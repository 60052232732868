<template>
  <ActionModal
    v-if="isModalShown"
    :width="'calc(100% - 50px)'"
    :height="'calc(100% - 50px)'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">
        {{ currentUser.role === 8 ? 'My Sales Team' : 'Attached Users' }}
      </div>
      <div class="pipeline-value">
        Total pipeline for this month: {{ formatPipelineValue(pipelineValue) }}
      </div>
      <div class="btns-wrapper">
        <ActionButton
          v-if="hideAttachButton"
          :width="'120px'"
          :height="'44px'"
          :text="'Export'"
          :style="{ 'margin-top': '0' }"
          @click="downloadReport"
        />
        <div class="close-modal-btn" @click="$emit('hideModal', $event)"></div>
      </div>
    </div>
    <div class="attach-user">
      <ActionButton
        v-if="!hideAttachButton"
        :width="'120px'"
        :height="'44px'"
        :text="'Export'"
        @click="downloadReport"
      />
      <ActionButton
        v-if="!hideAttachButton"
        :width="'100px'"
        :height="'44px'"
        :text="'Attach'"
        @click="showModalAttachUsers"
      />
      <AttachUsersModal
        :isModalShown="isModalAttachUsersShown"
        :managerId="memberId"
        @hideModal="closeModalAttachUsers"
      />
    </div>
    <div class="modal-table-body">
      <MainCard class="modal-main-card">
        <CardTableHeader
          :isExpirationDateShown="true"
          :isMembersStatusShown="false"
          :isMemberLastLoginShown="true"
          :isNameSortArrowHiddent="true"
          :isMemberRoleShown="true"
          :hideMemberCompany="true"
          :hideMemberRole="true"
          :isMemberPipelineValueShown="true"
          :isMemberMonthlyRevenueGoalShown="true"
          :order="order"
        />
        <DividerLine />
        <div
          class="attached-members-list"
          v-if="!errorMessage && !isLoading && !isEmpty"
        >
          <ManageUsersCardRow
            v-for="mem in currentPage"
            :key="mem.id"
            :id="mem.id"
            :name="mem.full_name"
            :status="mem.subscription.status"
            :lastLoginDate="mem.last_login_date"
            :expirationDate="mem.subscription.current_period_end"
            :pipelineValue="mem.pipeline_value"
            :monthlyRevenueGoal="mem.monthly_revenue_goal"
            :confidenceLevel="mem.confidence_level"
            :nonRevenueGoal="mem.non_revenue_goal"
            :menuActions="displayedMenuActions"
            :actionMenuWidth="'282px'"
            :actionMenuTop="'45px'"
            :role="mem.role"
            :hideDeleteAction="hideAttachButton"
            @actionMenuItemClick="
              handleActionMenuClick(
                $event,
                mem.full_name,
                mem.id,
                mem.subscription.current_period_end
              )
            "
          />
        </div>
        <div v-if="!isLoading && isEmpty" class="empty-list">
          You don’t have attached users to this Sales Manager. Please press
          “Attach” button to add them
        </div>
        <Spinner v-if="isLoading" />
      </MainCard>
    </div>
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextPage"
    />
    <LookInModal
      :isModalShown="isModalLookInShown"
      :memberId="memberLookInId"
      @hideModal="isModalLookInShown = false"
    />
  </ActionModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ActionModal from '@/components/common/ActionModal.vue'
import MainCard from '@/components/common/mainContent/MainCard.vue'
import DividerLine from '@/components/common/DividerLine.vue'
import Spinner from '@/components/common/Spinner.vue'
import CardTableHeader from '@/components/common/mainContent/CardTableHeader.vue'
import ManageUsersCardRow from './ManageUsersCardRow.vue'
import CardFooter from '@/components/common/mainContent/CardFooter.vue'
import LookInModal from '@/components/admin/LookInModal.vue'
import ActionButton from '@/components/common/ActionButton.vue'
import AttachUsersModal from '@/components/admin/members/actionModals/AttachUsersModal.vue'
import { convertUTCDateTimeToLocal } from '@/utils/helpers'

export default {
  name: 'ManageUsersModal',
  props: ['isModalShown', 'isModalLoading', 'memberId', 'hideAttachButton'],
  components: {
    ActionModal,
    MainCard,
    DividerLine,
    Spinner,
    CardTableHeader,
    ManageUsersCardRow,
    CardFooter,
    LookInModal,
    ActionButton,
    AttachUsersModal,
  },
  data() {
    return {
      isLoading: false,
      errorMessage: '',
      order: '',
      currentPageNumber: 1,
      pageSize: 'All',
      isActionMenuShown: false,
      isModalLookInShown: false,
      isModalAttachUsersShown: false,
      memberLookInId: '',
      menuActions: {
        LOOK_IN: 'Look in',
        REMOVE_MEMBER: 'Remove Member',
      },
      actionActive: false,
      RowsPerPage: ['All', 10, 20, 50],
    }
  },
  watch: {
    async isModalShown(newValue) {
      if (newValue) {
        await this.getInitialData()
      }
    },
  },
  computed: {
    ...mapState(['users', 'currentUser']),

    membersData() {
      return this.users.attachedUsersList
    },
    pipelineValue() {
      return this.users.pipelineValue
    },
    currentPage() {
      if (this.pageSize === 'All') {
        return this.membersData
      }
      const start =
        this.currentPageNumber === 1
          ? 0
          : this.currentPageNumber * this.pageSize - this.pageSize

      const stop =
        this.currentPageNumber === 1
          ? this.pageSize
          : this.currentPageNumber * this.pageSize

      const page = this.membersData.slice(start, stop)
      return page
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toItems() {
      if (this.pageSize === 'All') return this.membersData?.length || 0

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
    totalItemsCount() {
      return this.users.attachedUsersList?.length
    },
    lastId() {
      return this.currentPage.length ? this.currentPage.pop().id : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1

      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
    isEmpty() {
      return !this.currentPage.length
    },
    displayedMenuActions() {
      const displayedMenuActions = [this.menuActions.LOOK_IN]

      if (!this.hideAttachButton) {
        displayedMenuActions.push(this.menuActions.REMOVE_MEMBER)
      }
      return displayedMenuActions
    },
  },
  methods: {
    ...mapActions([
      'getAttachedUsersList',
      'unassignUserFromManager',
      'clearMembers',
      'getNextUsersPage',
    ]),
    dayConst(value) {
      return ('0' + new Date(value * 1000).getUTCDate()).slice(-2)
    },
    monthConst(value) {
      return ('0' + (new Date(value * 1000).getUTCMonth() + 1)).slice(-2)
    },
    yearConst(value) {
      return new Date(value * 1000).getFullYear()
    },
    lastLogin(value) {
      return this.convertUTCDateTimeToLocal(value)
    },
    downloadBlob(content, filename, contentType) {
      const blob = new Blob([content], { type: contentType })
      const url = URL.createObjectURL(blob)

      const pom = document.createElement('a')
      pom.href = url
      pom.setAttribute('download', filename)
      pom.click()
    },
    downloadReport() {
      const csvArr = [
        [
          'Name,Expiration Date,Last Login,Pipeline Value,Monthly Revenue Goal,Confidence Level,Non Revenue Goal',
        ],

        ...this.membersData.map((m) => {
          const extDate = m.subscription.current_period_end
          const name = m.full_name || ''
          const lastLogin = m.last_login_date
            ? convertUTCDateTimeToLocal(m.last_login_date)
            : '-'
          const expirationDate = extDate
            ? this.monthConst(extDate) +
              '/' +
              this.dayConst(extDate) +
              '/' +
              this.yearConst(extDate)
            : '-'
          const pipelineValue = m.pipeline_value ?? '-'
          const monthlyRevenueGoal = m.monthly_revenue_goal ?? '-'
          const confidenceLevel = m.confidence_level
            ? m.confidence_level + '%'
            : '-'
          const nonRevenueGoal = m.non_revenue_goal
            ? `"${m.non_revenue_goal.replace(/"/g, '""')}"`
            : '-'
          const csvData = `${name},${expirationDate},${lastLogin},${pipelineValue},${monthlyRevenueGoal},${confidenceLevel},${nonRevenueGoal}`

          return [csvData]
        }),
      ]
        .map((e) => e.join(','))
        .join('\n')
      this.downloadBlob(csvArr, `users-report.csv`, 'text/csv;charset=utf-8;')
    },
    formatPipelineValue(value) {
      if (value && value > 0) {
        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '$0'
    },
    async getInitialData() {
      this.isLoading = true
      try {
        await this.getAttachedUsersList(this.memberId)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.errorMessage = error
      }
    },
    async getFirstPage() {
      this.currentPageNumber = 1

      try {
        await this.getNextUsersPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          searchTerm: '',
          memberId: this.memberId,
        })
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async unassignUser(id) {
      await this.unassignUserFromManager({
        managerId: this.memberId,
        memberId: id,
      })
    },

    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage

      await this.clearMembers()
      await this.getFirstPage()
    },
    async handleActionMenuClick(action, _, memberId = null) {
      this.isActionMenuShown = false
      this.isLoading = true
      this.membersIds = [memberId]

      if (!this.membersIds.length) {
        this.isLoading = false
        return
      }

      try {
        if (action === this.menuActions.REMOVE_MEMBER) {
          this.unassignUser(memberId)
        } else if (action === this.menuActions.LOOK_IN) {
          this.isModalLookInShown = true
          this.memberLookInId = memberId
        }
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.currentPageNumber -= 1
    },
    async getNextPage() {
      if (
        (this.currentPageNumber !== 0 &&
          this.toItems === this.totalItemsCount) ||
        this.isLoading
      )
        return

      this.currentPageNumber += 1
      if (this.currentPage.length) return

      this.isLoading = true

      try {
        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          searchTerm: '',
          pageNumber: this.currentPageNumber,
        }
        await this.getNextMembersPage(payload)
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    showModalAttachUsers() {
      this.isModalAttachUsersShown = true
    },
    async closeModalAttachUsers() {
      this.isModalAttachUsersShown = false
      await this.getInitialData()
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styleVars';
.attached-members-list {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.empty-list {
  margin-top: 40px;
  font-size: $fontSizeLarger;
}

.attach-user {
  margin-right: 40px;
  align-self: flex-end;
  display: flex;
  gap: 24px;
}

.attached-footer {
  margin-bottom: 20px;
}
.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btns-wrapper {
    display: flex;
    align-items: center;
    gap: 64px;
  }
  .modal-title {
    font-size: $fontSizeModalTitle;
    color: $fontColorBlack;
  }

  .pipeline-value {
    font-size: $fontSizeLarger;
    color: $fontColorBlack;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}
.modal-header-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: calc(100% - 80px);

  .goal-values {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    flex-direction: column;

    .title {
      font-size: $fontSizeTitle;
    }

    .current-date {
      font-size: $fontSizeMinified;
      color: $fontColorGray;
    }
  }

  .vertical-divider {
    width: 1px;
    height: 54px;
    margin-left: 20px;
    top: 35px;
    background: #c9cde8;
  }

  .closed-value-container {
    margin-left: 20px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
}
.modal-header-menu {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: calc(100% - 80px);
  border-bottom: 1px solid var(--gray-200, #e2e8f0);

  .tab-modal-element-wrapper {
    list-style-type: none;
    margin: 0;
    padding: 0px;
    overflow: hidden;
    background-color: white;
    cursor: pointer;
    align-items: flex-start;

    .tab-element-active-state {
      display: inline;
      float: left;
      padding: 14px 14px;
      color: #69c9a0;
      border-bottom: 4px solid #69c9a0;

      title {
        display: block;
        color: #69c9a0;
        text-decoration: none;
        text-align: center;
      }
    }

    .tab-element {
      display: inline;
      float: left;
      padding: 14px 14px;

      title {
        display: block;
        text-decoration: none;
        text-align: center;
      }
    }
  }
}
.modal-table-body {
  width: calc(100% - 80px);
  height: calc(100% - 190px);

  .clients-list {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .prospects-list {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  .error-message {
    width: 100%;
    height: calc(100% - 204px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redWarningColor;
  }

  .table-sort-options {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .sort-table-options {
      list-style-type: none;
      margin: 0;
      padding: 0px;
      color: $fontColorBlack;
      overflow: hidden;
      background-color: white;
      cursor: pointer;
      align-items: flex-start;

      .active-sort-state {
        display: inline;
        float: left;
        padding: 14px 14px;
        color: #69c9a0;
      }

      .sort-table-item {
        display: inline;
        float: left;
        padding: 14px 14px;
      }
    }
  }
  .modal-main-card {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
</style>
